import React from "react"
import MainLayout from 'src/layouts/Main'

export default () => {
    return(
    <MainLayout>
        <div>Hello world resources!</div>
    </MainLayout>
    )
}
